<template>
  <div v-if="layout">
    <!-- common -->
    <rectangle-element
      v-if="layout.body && layout.body.visible"
      :layout="layout.body"
      :skeleton="skeleton"
    ></rectangle-element>

    <!-- main -->
    <image-element
      v-if="layout.image && layout.image.visible && content.image"
      :url="content.image"
      :layout="layout.image"
      :skeleton="skeleton"
    ></image-element>
    <section-element
      v-if="layout.source && layout.source.visible && content.source"
      :text="content.source"
      :icon="['fas', 'rss']"
      :layout="layout.source"
      :skeleton="layout.skeleton"
      :showIcon="showIconSource"
    ></section-element>
    <section-element
      v-if="layout.date && layout.date.visible"
      :text="date"
      :icon="['far', 'calendar-alt']"
      :layout="layout.date"
      :skeleton="layout.skeleton"
    ></section-element>
    <section-element
      v-if="layout.location && layout.location.visible && content.location"
      :text="content.location"
      :icon="['fas', 'map-marker-alt']"
      :layout="layout.location"
      :skeleton="layout.skeleton"
    ></section-element>

    <!-- footer -->

    <!-- common -->
    <section-element
      v-if="layout.header && layout.header.visible"
      :icon="['fas', 'rss']"
      :layout="layout.header"
      :skeleton="layout.skeleton"
    ></section-element>

    <text-element
      v-if="layout.title && layout.title.visible"
      :text="content.title"
      :layout="layout.title"
      :skeleton="skeleton"
    ></text-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import moment from "moment";

export default {
  name: "LiveNewsElement",
  mixins: [layoutMixin],
  components: {
    SectionElement: () => import("@/components/core/SectionElement"),
    TextElement: () => import("@/components/core/TextElement"),
    RectangleElement: () => import("@/components/core/RectangleElement"),
    ImageElement: () => import("@/components/core/ImageElement"),
  },
  props: {
    content: Object,
    dateFormat: { type: String, default: "LLL" },
    showIconSource: { type: Boolean, default: true },
  },
  data() {
    return {
      num: 0,
    };
  },
  mounted() {},
  destroyed() {},
  computed: {
    date: function() {
      return moment(this.content.date).format(this.dateFormat);
    },
    // source: function(){
    //   return "Notizie da "
    // }
  },
  methods: {},
};
</script>
